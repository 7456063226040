import React from "react";
import ReactDOM from "react-dom";
import "./index.styl";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {StoreProvider} from "./providers/storeProvider";
import {ApolloProvider} from "react-apollo";
import client from "./services/apollo";
import TagManagerService from "@services/TagManagerService";

TagManagerService.init();

ReactDOM.render(
	<StoreProvider>
		<ApolloProvider client={client}>
			<App/>
		</ApolloProvider>
	</StoreProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
