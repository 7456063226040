import classNames from 'classnames';
import React, {FC, useCallback, useState} from 'react';
import {CancelOrderModal} from '../../../../../../components/Modals/CancelOrderModal/CancelOrderModal';
import ChangeTimeModal from '../../../../../../components/MyOrdersList/EcomOrderItem/ChangeTimeModal/ChangeTimeModal';

import {ICONS} from '../../../../../../components/UI/Icon/enums';
import Icon from '../../../../../../components/UI/Icon/Icon';
import {BASKET_STATE_CLASS_LABELS, DELIVERY_TYPES} from '../../../../../../constants';
import getTemplateText from '../../../../../../services/TextService';
import {ChequeDataType, PurchaseDetailsItemType} from '../../../hooks/usePurchaseDetails/types';

import './PurchaseItemGeneralInfo.styl';

export type PurchaseItemGeneralPropsType = {
	itemDetails: PurchaseDetailsItemType;
	refetchOrder: (item: PurchaseDetailsItemType) => void;
	chequeData?: Omit<ChequeDataType, "filialId">;
}

const PurchaseItemGeneralInfo: FC<PurchaseItemGeneralPropsType> = ({itemDetails, refetchOrder}) => {
	const {stateId, stateText, deliveryDateText, deliveryAddress, deliveryType, filialName, note} = itemDetails;
	const deliveryTitle = deliveryType === DELIVERY_TYPES.DELIVERY_HOME || deliveryType === DELIVERY_TYPES.DELIVERY_FAR_AWAY
		? "Адреса доставки"
		: "Адреса магазину";
	const addressValue = deliveryType === DELIVERY_TYPES.DELIVERY_HOME || deliveryType === DELIVERY_TYPES.DELIVERY_FAR_AWAY
		? deliveryAddress
		: filialName;
	const statusIcon = BASKET_STATE_CLASS_LABELS[stateId] === "refused" ? ICONS.cancel_order : ICONS.checkmark;
	const editTime = itemDetails.actions.includes("changeDeliveryTime");
	const [isOpenedChangeTimeModal, setOpenedChangeTimeModal] = useState(false);
	const [isOpenedCancellingModal, setOpenedCancellingModal] = useState(false);
	const [isNoteOpen, setIsNoteOpen] = useState(false);
	console.log(itemDetails)
	const openChangeTimeModal = useCallback((e) => {
		e.preventDefault();
		setOpenedChangeTimeModal(true);
	}, []);

	/***
 * open cancel modal
 */
	const openCancellingModal = () => {
		setOpenedCancellingModal(true);
	};

	/***
	 * close canceling modal
	 */
	const closeCancellingModal = () => {
		setOpenedCancellingModal(false)
	};

	const handleOpenNote = () => {
		setIsNoteOpen(prev => !prev);
	}

	return (
		<div className='purchase-general-info-wrapper'>
			<div className='purchase-general-info_status'>
				<div className={classNames('state', BASKET_STATE_CLASS_LABELS[stateId])}>
					<Icon icon={statusIcon} />
					<span>{stateText}</span>
				</div>
				{deliveryType !== DELIVERY_TYPES.UNKNOWN &&
					<span className='date'>{deliveryDateText}</span>}
				{editTime && <div onClick={openChangeTimeModal} className='change-time'>
					<Icon raw icon={ICONS.edit} />
					<span>{getTemplateText("myPurchases.order.change-time")}</span>
				</div>}
				{itemDetails.actions.includes("cancel") && <div onClick={openCancellingModal} className='cancel-order'>
					<Icon icon={ICONS.cancel_order} />
					<span>{getTemplateText("myPurchases.order.cancel")}</span>
				</div>}
			</div>
			{addressValue &&
				<div className='purchase-general-info_address'>
					<div className='general-info'>
						<Icon raw icon={ICONS.addressPointer} />
						<span className='description'>{deliveryTitle}</span>
					</div>
					<span className='value'>{addressValue}</span>
				</div>}
			{note &&
				<div className='purchase-general-info_comment'>
					<div className={classNames('general-info', {isActive: isNoteOpen})} onClick={handleOpenNote}>
						<Icon icon={ICONS.warning} />
						<span className='description'>{getTemplateText("myPurchases.order.comment")}</span>
						<Icon icon={ICONS.faqArrow} />
					</div>
					{isNoteOpen && <span className='value'>{note}</span>}
				</div>}
			{isOpenedChangeTimeModal &&
				itemDetails.actions.includes("changeDeliveryTime") &&
				<ChangeTimeModal
					isOpen={true}
					onClose={() => setOpenedChangeTimeModal(false)}
					orderId={itemDetails.id}
					activeTime={itemDetails.deliveryDateFrom}
					refetchData={() => refetchOrder(itemDetails)} />}
			{
				isOpenedCancellingModal &&
				<CancelOrderModal
					title={getTemplateText("cancellingFeedbackForm.title")}
					paid={itemDetails.paymentStateId === 1}
					id={itemDetails.id}
					sum={itemDetails.sumOut}
					isOpen={true}
					onClose={closeCancellingModal}
					orderHistoryRefetch={() => refetchOrder(itemDetails)}
					itemDetails={itemDetails}
				/>
			}
		</div>
	)
}

export default PurchaseItemGeneralInfo;