import TagManager from "react-gtm-module";

const tagManagerConfig = {
    gtmId: 'GTM-WVVZNR4S'
};
type tagLayerType = {
    dataLayerName?: string;
    dataLayer: any;
};

function postToLayer(layer: tagLayerType) {
    TagManager.dataLayer(layer);
}

const TagManagerService = {
    init() {
        TagManager.initialize(tagManagerConfig);
    },
    refundEvent(order) {
        const dataLayer = {
            event: "refund",
            ecommerce: {
                order_number: order.orderNumber,
                transaction_id: order.id,
                affiliation: order.filialName,
                value: order.sumOut,
                currency: "UAH",
                payment_type: order.paymentTypeText,
                payment_state: order.paymentStateText,
                order_type: order.orderTypeText,
                delivery_type: order.deliveryTypeText,
                delivery_address: order.deliveryAddress,
                delivery_time: order.deliveryDateText,
                items: order.orderLines.map(item => ({
                    item_id: item.number + '',
                    item_name: item.name,
                    item_variant: item.unit,
                    price: item.price,
                    quantity: item.orderCount,
                    item_total: item.orderSum,
                    availability: item.isAvailable ? "available" : "unavailable",
                }))
            }
        }
        postToLayer({
            dataLayer
        })
    }
};

export default TagManagerService;