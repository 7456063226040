import React, {FC, useEffect} from 'react';
import {Link} from "react-router-dom";

import {ICONS} from "../../../UI/Icon/enums";
import {ROUTE} from "../../../../services/RouteService/enums";
import {urlTo} from "../../../../services/RouteService/RouteService";
import {FormattedHTMLMessage} from "../../../../services/TextService";
import {checkUIVersion} from "../../../../services/VersionControlServices";
import WithContext from "../../../HOCs/WithContext/WithContext";

import Icon from "../../../UI/Icon/Icon";
import Button from "../../../UI/Button/Button";

import foraClub from "images/fora-club.png";
import './MenuHeader.styl';


type MenuHeaderProps = {
	toogle: any;
	user;
}
const MenuHeader: FC<MenuHeaderProps> = ({user, toogle}) => {
	const{firstName, lastName, currentBonus, currentBalance} = user;

	useEffect(() => {
		checkUIVersion();
		// eslint-disable-next-line
	}, []);

	/**
	 * get user name displayed in header depending on whether lastName or firstName are absent simultaneously
	 * @returns {string}
	 */
	const getUserName = () => {
		if (!(firstName && lastName)) {
			return "Гість"
		} else {
			return `${firstName || ""} ${lastName ? lastName[0] : ""}.`
		}
	};

	return (
			<div className="menu-header-wrapper">
				<div className="menu-header">
					<div className="min-width">

						<Button className="menu-btn" onClick={toogle}>
							<Icon icon={ICONS.menu}/>
						</Button>
						<Link to={urlTo(ROUTE.PERSONAL_PAGE)} className="menu-logo logo">
							<img width="50" src={foraClub} alt="Фора клуб"/>
						</Link>
						<div className="menu-header-content">
							<div className={"menu-header-item account"}>
								<div className="menu-header_button">
									<Link
										className="a-like"
										to={urlTo(ROUTE.PERSONAL_PAGE)}
									>
										<Icon icon={ICONS.person}/>
											<span className="menu-header-item_title menu-header__personal-page-button_title logged">
											{getUserName()}
									</span>
									</Link>
								</div>

								{!!(currentBonus || currentBalance) && <div className="menu-header_user-vouchers">
									{currentBonus ? "Доступно" : "На рахунку" }
									<br/>
									<div className="account-available__vouchers">
										<span className="account-available__vouchers__amount">
											<span>{currentBonus || currentBalance}</span>
										</span>
										{/*{currentBonus
											?	<span className="account-available__vouchers__currency">
													<FormattedHTMLMessage id="personalPage.vouchers.currency.hrn"/>
												</span>
											:	<FormattedHTMLMessage
													id="personalPage.vouchers.currency.point"
													values={{number: currentBalance}}
												/>
										}*/}
									</div>
								</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
		)

};

export default WithContext(
	(state) => ({
		user: state.user,
	})
)(MenuHeader);